import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import ABMRightRoundOne from "../images/ABMRightRoundOne.png"
import Shapefour from "../images/bannerElement/white-design-element-abm.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import AssembleABM from "../images/icons/AccountBasedMarketing/AssembleABMtechnologystack.svg"
import CraftPersona from "../images/icons/AccountBasedMarketing/CraftPersonaDrivenMessaging.svg"
import IdentifyIdeal from "../images/icons/AccountBasedMarketing/IdentifyIdealCustomerProfiles.svg"
import IntegratedContent from "../images/icons/AccountBasedMarketing/IntegratedContentStrategy.svg"
import MeasureAndOptimize from "../images/icons/AccountBasedMarketing/MeasureAndOptimize.svg"
import OrchestrateMultiphase from "../images/icons/AccountBasedMarketing/OrchestrateMultiphaseCampaigns.svg"
import "../styles/account-based-marketing.css"

export default function AccountBasedMarketing() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Account Based Marketing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="ABM" />
      </Helmet>
      <Layout>
        <section id="ABMBanner">
          <div class="container">
            <div class="banner-holder">
              <div class="images">
                <div className="img-one image-shape">
                  <img src={Shapeone} alt="Image" />
                </div>
                <div className="img-two image-shape">
                  <img src={Shapetwo} alt="Image" />
                </div>
                <div className="img-three image-shape">
                  <img src={Shapethree} alt="Image" />
                </div>
                <div className="img-four image-shape">
                  <img src={Shapefour} alt="Image" />
                </div>
              </div>
              <div class="title">
                <div class="banner-title">
                  <h1>ABM to accelerate your growth</h1>
                  <p>
                    Orchestrating personalized engagement at enterprise scale
                  </p>
                  <a
                    className="button"
                    id="3dsolutionvideo"
                    href="/contact-us/"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="ABMPageIntro">
          <div class="container">
            <h2>Drive predictable revenue with Account-based Marketing</h2>
            <p>
              Infuse your marketing strategy with intelligence and AI-powered
              automation that spans the entire spectrum of Account-based
              Marketing, addressing key components from persona creation to
              high-value opportunity generation.
            </p>
          </div>
        </section>
        <section id="ABMWeDeliver">
          <div className="container">
            <h2>Our holistic ABM strategy</h2>
            <div className="horizontalImageIconSec">
              <div className="moduleWrap">
                <div className="eachModule">
                  <div className="icons">
                    <img src={IdentifyIdeal} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <h3>Identify ideal Customer Profiles (ICPs)</h3>
                    <p>
                      We refine or build your ICP based on industry, size, and
                      demographics.
                    </p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={OrchestrateMultiphase} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <h3>Orchestrate Multi-phase Campaigns</h3>
                    <p>
                      Brand Awareness: Build recognition
                      <br />
                      Engagement & Nurture: Drive interest
                      <br />
                      Conversion: Support sales
                    </p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={CraftPersona} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <h3>Craft Persona-driven Messaging</h3>
                    <p>
                      Deliver precise messages tailored to each persona's needs,
                      intent, and buying stage.
                    </p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={IntegratedContent} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <h3>Integrated Content Strategy</h3>
                    <p>
                      Create a cohesive content plan across formats like videos,
                      reports, and whitepapers aligned to each campaign phase.
                    </p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={AssembleABM} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <h3>Assemble ABM Technology Stack</h3>
                    <p>
                      We equip you with the optimal martech tools for executing
                      impactful ABM campaigns.
                    </p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={MeasureAndOptimize} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <h3>Measure and Optimize</h3>
                    <p>
                      Continuously track performance metrics and optimize
                      campaigns for maximum ROI.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="ABMTextImageSection">
          <div class="container">
            <div class="text-image-holder">
              <div class="text-wrap">
                <h2>
                  The ABM advantage: <br />
                  Our strategy drives results
                </h2>
                <p>
                  Experience the game-changing impact of a comprehensive ABM
                  strategy with our approach that doesn't just check boxes - it
                  delivers measurable results that will revolutionize your
                  marketing and sales engine.
                </p>
                <ul class="bullet-point">
                  <li>Generate Qualified leads from Target Accounts</li>
                  <li>Boost Marketing and Sales Efficiency</li>
                  <li>Make Data-Driven Decisions for Better ROI</li>
                  <li>Focus Resources on High-Value Opportunities</li>
                </ul>
              </div>
              <div class="img-wrap">
                <img alt="Copy write" src={ABMRightRoundOne} />
              </div>
            </div>
          </div>
        </section>
        {/* <section id="ABMPartnerLogoSection">
                    <div class="container">
                        <div class="logo-wrap">
                            <div class="each-logo">
                                <img src={Cohere} alt="Cohere" />
                            </div>
                            <div class="each-logo">
                                <img src={Lenovo} alt="Lenovo" />
                            </div>
                            <div class="each-logo">
                                <img src={Coveo} alt="Coveo" />
                            </div>
                            <div class="each-logo">
                                <img src={AMEX} alt="Amex" />
                            </div>
                            <div class="each-logo">
                                <img src={Macroair} alt="Macroair" />
                            </div>
                            <div class="each-logo">
                                <img src={Airbnb} alt="Airbnb" />
                            </div>
                        </div>
                    </div>
                </section> */}
        <section id="ABMcontactus-section">
          <div class="container">
            <h2>Ready to unlock the power of ABM?</h2>
            <a className="button" id="3dsolutionvideo" href="/contact-us/">
              Get in touch
            </a>
          </div>
        </section>
      </Layout>
    </>
  )
}
